import React from "react";
import Hyphenated from "react-hyphen";
// import ru from "hyphenated-ru";

import { useIsSpymaster } from "../utils/useIsSpymaster";
import { useGetTeamByIndex } from "../utils/useGetTeamByIndex";

import "./card.css";
import useStore from "../store";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useGetPlayersByVoteIds } from "../utils/useGetPlayersByVoteIds";

const Card = ({ card, index }) => {
  const userId = useStore((state) => state.userId);
  const gameCode = useParams().gameCode;
  const game = useStore((state) => state.game);

  const isSpymaster = useIsSpymaster(userId);
  const isRevealed = card.revealed;

  const assignedCardTeam = card.team;

  const cardColorMap = {
    1: useGetTeamByIndex(1)?.color?.toLowerCase(),
    0: useGetTeamByIndex(0)?.color?.toLowerCase(),
    2: useGetTeamByIndex(2)?.color?.toLowerCase(),
    assassin: "assassin",
    neutral: "neutral",
  };
  const cardColor = cardColorMap[assignedCardTeam];

  const handleCardClick = () => {
    try {
      if (game.status !== "In-Progress") return;
      if (isSpymaster) {
        return;
      }

      if (isRevealed) {
        return;
      }

      const operativeTeamIndex = game.teams.findIndex((team) =>
        team.operatives.find((player) => player._id === userId)
      );

      if (operativeTeamIndex === -1) return;

      if (game.currentTeamIndex !== operativeTeamIndex) {
        return;
      }

      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/games/voteCard`, {
          gameCode,
          userId,
          cardId: card._id,
        })
        .then((res) => {})
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const playerVotes = useGetPlayersByVoteIds(card.votes);

  const canSeeCardColor = isSpymaster || isRevealed || game.status === "Ended";
  const shouldSeeOpaqueCard =
    isRevealed && (game.status === "Ended" || isSpymaster);

  const isLastRevealedCard = card._id === game.lastRevealedCard;

  return (
    <div
      className={`card card-${index} ${
        canSeeCardColor ? `card--${cardColor}` : ""
      } ${shouldSeeOpaqueCard ? "card--revealed" : ""} ${
        isLastRevealedCard ? "card--last-revealed" : ""
      }`}
      onClick={() => handleCardClick()}
    >
      {playerVotes.length > 0 && (
        <div className="card__votes">
          <span>{playerVotes.length}</span>
        </div>
      )}
      {/* <div className="card__votes">
        {[1, 2, 3, 4].map((player, index) => (
          <div key={index} className="card__vote">
            O
          </div>
        ))}
      </div> */}
      <Hyphenated>{card.word}</Hyphenated>
    </div>
  );
};

export default Card;
