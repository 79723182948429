import React from "react";
import useStore from "../store";
import Card from "./Card";
import "./grid.css";

const Grid = () => {
  const game = useStore((state) => state.game);
  const { cards } = game;

  const gridSizesClassMap = {
    25: "5",
    36: "6",
  };

  const gridSizeClass = gridSizesClassMap[game.config.cardCount];

  return (
    <div className={`grid grid--${gridSizeClass}`}>
      {cards.map((card, index) => {
        return <Card key={index} card={card} index={index} />;
      })}
    </div>
  );
};

export default Grid;
