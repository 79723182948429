import { useEffect, useState } from "react";

const useHandleSocketDisconnect = (socket) => {
  const [isDisconnected, setIsDisconnected] = useState(false);
  const [reconnectAttempts, setReconnectAttempts] = useState(0);

  useEffect(() => {
    if (!socket) return;

    const handleDisconnect = () => {
      attemptReconnect();

      setTimeout(() => {
        if (!socket.connected) {
          setIsDisconnected(true);
        }
      }, 2000);
    };

    const handleReconnect = () => {
      setIsDisconnected(false);
      setReconnectAttempts(0);
    };

    const attemptReconnect = () => {
      if (reconnectAttempts < 3 && !socket.connected) {
        // Limit the number of reconnection attempts
        setTimeout(() => {
          socket.connect();
          setReconnectAttempts((attempts) => attempts + 1);
        }, Math.pow(2, reconnectAttempts) * 1000);
      }
    };

    const handleOffline = () => {
      setIsDisconnected(true);
    };

    const handleOnline = () => {
      // Check if the socket has not reconnected automatically
      if (!socket.connected) {
        attemptReconnect();
      } else {
        // If already connected, reset the UI state
        setIsDisconnected(false);
        setReconnectAttempts(0);
      }
    };

    // Event listeners for socket connection
    socket.on("disconnect", handleDisconnect);
    socket.on("connect", handleReconnect);

    // Event listeners for online/offline
    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      socket.off("disconnect", handleDisconnect);
      socket.off("connect", handleReconnect);
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, [socket, reconnectAttempts, isDisconnected]);

  return isDisconnected;
};

export default useHandleSocketDisconnect;
