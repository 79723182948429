import useStore from "../store";

export const useGetPlayersByVoteIds = (voteIds) => {
  const game = useStore((state) => state.game);
  const players = voteIds.map((voteId) =>
    game.players.find((player) => player._id === voteId)
  );

  return players;
};
