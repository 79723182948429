import axios from "axios";

export const makeHost = async (gameCode, userId, playerToMakeHostId) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/games/makeHost`,
    {
      gameCode,
      userId,
      playerToMakeHostId,
    }
  );

  return response.data;
};
