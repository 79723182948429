// Higher-order function for tracking button clicks
const withTracking = (originalOnClick, buttonName) => {
  return (event) => {
    // Send event data to Google Analytics
    window.gtag &&
      window.gtag("event", "button_click", {
        event_category: "Button",
        event_label: buttonName,
      });

    // Call the original event handler
    if (originalOnClick) {
      originalOnClick(event);
    }
  };
};

export default withTracking;
