import axios from "axios";

export const kickPlayer = async (gameCode, userId, playerToKickId) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/games/kickPlayer`,
    {
      gameCode,
      userId,
      playerToKickId,
    }
  );

  return response.data;
};
