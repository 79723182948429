module.exports = {
  "@context": "http://schema.org",
  "@type": "VideoGame",
  name: "Cryptik",
  description:
    "Cryptik is an online multiplayer strategy word puzzle game, offering a unique blend of wordplay and brain-teasing challenges. Inspired by classic games like Codenames.",
  genre: ["Puzzle", "Strategy", "Word"],
  url: "https://cryptik.me",
  image: "https://cryptik.me/cryptik-logo.png",
  applicationCategory: "Game",
  operatingSystem: "Web",
  aggregateRating: {
    "@type": "AggregateRating",
    ratingValue: "4.5",
    ratingCount: "1234",
  },
  offers: {
    "@type": "Offer",
    price: "0.00",
    priceCurrency: "USD",
    availability: "http://schema.org/InStock",
  },
  publisher: "Sami",
  playMode: "Multiplayer",
  browserRequirements: "Requires JavaScript and HTML5",
  releaseDate: "2023-11-23",
  keywords:
    "code names, code, names, online, game, strategy game, puzzle, word game, free, f2p, multiplayer, web, browser, game",
};
