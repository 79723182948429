import React from "react";
import useStore from "../store";
import axios from "axios";
import {
  FaDiceFive,
  FaLock,
  FaLockOpen,
  FaPause,
  FaPlay,
  FaSync,
} from "react-icons/fa";
import { useIsHost } from "../hooks/useIsHost";
import withTracking from "../utils/withTrackingClick";

const TimeControlButtons = () => {
  const game = useStore((state) => state.game);
  const userId = useStore((state) => state.userId);
  const setShowSettings = useStore((state) => state.setShowSettings);
  const gameCode = game?.code;

  const handleStartGame = () => {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/games/start`, {
        userId,
        gameCode,
      })
      .then(() => {
        setShowSettings(false);
      });
  };

  const handlePauseGame = () => {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/games/pause`, {
        userId,
        gameCode,
      })
      .then(() => {});
  };

  const handleResumeGame = () => {
    console.log('can we resume??');
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/games/resume`, {
        userId,
        gameCode,
      })
      .then(() => {
        setShowSettings(false);
      });
  };

  const handleReInitGame = ({ shuffleTeams } = { shuffleTeams: false }) => {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/games/reInit`, {
        userId,
        gameCode,
        config: game?.config,
        shuffleTeams,
      })
      .then(() => {
        setShowSettings(false);
      });
  };

  const handleLockGame = () => {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/games/lock`, {
        userId,
        gameCode,
      })
      .then(() => {});
  };

  const handleReinitShuffleTeams = () => {
    handleReInitGame({ shuffleTeams: true });
  };

  const isHost = useIsHost(userId);

  if (!isHost) return;

  return (
    <div className="timer-controls">
      {game.status === "Ended" && (
        <button
          className={`icon-btn timer-control-btn`}
          onClick={withTracking(handleReInitGame, "Quick-RestartGame")}
        >
          <FaSync />
        </button>
      )}
      {game.status === "Ended" && (
        <button
          className={`icon-btn timer-control-btn timer-control-btn--shuffle`}
          onClick={() =>
            withTracking(handleReinitShuffleTeams, "Quick-ShuffleTeams")
          }
        >
          <FaDiceFive />
        </button>
      )}
      {(game.status === "Initialized" ||
        game.status === "Paused" ||
        game.status === "In-Progress") && (
        <>
          {game.status === "Initialized" && (
            <button
              className={`icon-btn timer-control-btn ${
                (game.status === "Initialized" || game.status === "Paused") &&
                "timer-control-btn--pulsing"
              }`}
              onClick={withTracking(handleStartGame, "Quick-StartGame")}
            >
              <FaPlay />
            </button>
          )}
          {game.status === "In-Progress" && (
            <button
              className={`icon-btn timer-control-btn ${
                (game.status === "Initialized" || game.status === "Paused") &&
                "timer-control-btn--pulsing"
              }`}
              onClick={withTracking(handlePauseGame, "Quick-PauseGame")}
            >
              <FaPause />
            </button>
          )}
          {game.status === "Paused" && (
            <button
              className={`icon-btn timer-control-btn ${
                (game.status === "Initialized" || game.status === "Paused") &&
                "timer-control-btn--pulsing"
              }`}
              onClick={withTracking(handleResumeGame, "Quick-ResumeGame")}
            >
              <FaPlay />
            </button>
          )}
        </>
      )}
      {!["Created"].includes(game.status) && (
        <button
          className="icon-btn timer-control-btn timer-control-btn--lock"
          onClick={withTracking(handleLockGame, "Quick-LockGame")}
        >
          {game.locked ? <FaLock /> : <FaLockOpen />}
        </button>
      )}
    </div>
  );
};

export default TimeControlButtons;
