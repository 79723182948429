import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import useStore from "./store";

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  // const { playerName, accessCode } = useStore();
  const { playerName, userId } = useStore();

  // if (!playerName || !accessCode) {
  if (!playerName || !userId) {
    localStorage.setItem("Cryptik_redirectPath", location.pathname);
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;
