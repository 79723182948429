import React, { useState } from "react";
import axios from "axios";
import "./LoginModal.css";
import useStore from "../store";
import withTracking from "../utils/withTrackingClick";

const LoginModal = ({ onLoginSuccess }) => {
  // const storeAccessCode = useStore((state) => state.setAccessCode);
  // const storedAccessCode = useStore((state) => state.accessCode);
  // const [accessCode, setAccessCode] = useState(storedAccessCode);
  // const [step, setStep] = useState(storedAccessCode ? 2 : 1);
  const [step, setStep] = useState(2);
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const setPlayer = useStore((state) => state.setPlayer);

  // const handleAccessCodeSubmit = async (e) => {
  //   e.preventDefault();
  //   setIsLoading(true);
  //   // Validate access code with backend
  //   try {
  //     // Replace with your API call
  //     await axios.post(
  //       `${process.env.REACT_APP_API_BASE_URL}/validate-access-code`,
  //       {
  //         code: accessCode?.toUpperCase(),
  //       }
  //     );
  //     setStep(2);
  //     localStorage.setItem("Cryptik_accessCode", accessCode);
  //     storeAccessCode(accessCode);
  //     setError("");
  //   } catch (err) {
  //     console.error(err);
  //     setError("Invalid or expired access code.");
  //   }
  //   setIsLoading(false);
  // };

  const handleNameSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Create user and proceed with login
    try {
      const token = Date.now() + "_" + Math.random().toString(36).substr(2, 9);
      localStorage.setItem("Cryptik_playerName", name);
      localStorage.setItem("Cryptik_playerToken", token);
      setPlayer(name, token);

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/users`,
        {
          name,
          token,
        }
      );
      onLoginSuccess(response.data); // Callback function to handle successful login
    } catch (err) {
      setError("Error creating user.");
    }
    setIsLoading(false);
  };

  // focus inputs on mount
  React.useEffect(() => {
    if (step === 1) {
      document.querySelector(".login-input").focus();
    }

    if (step === 2) {
      document.querySelector(".login-input").focus();
    }
  }, [step]);

  return (
    <div className="login-modal">
      <div className="login-modal__content">
        <h1>Welcome to Cryptik</h1>
        <h2>A strategy puzzle game</h2>

        <form onSubmit={handleNameSubmit}>
          <input
            className="login-input"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter player name"
            required
          />
          <button className="login-btn" type="submit" disabled={isLoading}>
            {isLoading ? <div className="spinner"></div> : "Enter"}
          </button>
        </form>
        {/* {step === 1 ? (
          <form onSubmit={handleAccessCodeSubmit}>
            <input
              className="login-input"
              type="text"
              value={accessCode}
              onChange={(e) => setAccessCode(e.target.value)}
              placeholder="Access Code"
              required
            />
            <button
              className="login-btn"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? <div className="spinner"></div> : "Verify Code"}
            </button>
          </form>
        ) : (
          <form onSubmit={handleNameSubmit}>
            <input
              className="login-input"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter player name"
              required
            />
            <button
              className="login-btn"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? <div className="spinner"></div> : "Enter"}
            </button>
          </form>
        )} */}
        {error && <p className="login-error">{error}</p>}
      </div>
    </div>
  );
};

export default LoginModal;
