import axios from "axios";

export const assignTeam = async (userId, gameCode, teamId, role) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/games/assignTeam`,
    {
      userId,
      gameCode,
      teamId,
      role,
    }
  );

  return response.data;
};
