import React, { useEffect, useState, useRef, useCallback } from "react";
import useStore from "../store";

const Timer = () => {
  const game = useStore((state) => state.game);

  const [timeLeft, setTimeLeft] = useState(game?.turnDuration * 1000);
  const timerRef = useRef(null);
  const socket = useStore((state) => state.socket);
  const gameCode = useStore((state) => state.game.code);

  // Function to start the timer countdown
  const startTimer = useCallback((endTime) => {
    if (timerRef.current) clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      const remaining = endTime - Date.now();
      if (remaining <= 0) {
        clearInterval(timerRef.current);
        setTimeLeft(0);
      } else {
        setTimeLeft(remaining);
      }
    }, 1000);
  }, []);

  // Function to pause the timer

  const pauseTimer = useCallback(() => {
    if (timerRef.current) clearInterval(timerRef.current);
  }, []);

  // Function to resume the timer
  const resumeTimer = useCallback(
    (endTime) => {
      startTimer(endTime);
    },
    [startTimer]
  );

  useEffect(() => {
    socket.emit("requestCurrentTimerState", { gameCode });
  }, [socket, gameCode]);

  // Setup Socket.io connection
  useEffect(() => {
    socket.on("timerUpdate", (data) => {
      switch (data.action) {
        case "start":
          startTimer(data.endTime);
          break;
        case "pause":
          pauseTimer();
          break;
        case "resume":
          resumeTimer(data.endTime);
          break;
        case "end":
          setTimeLeft(0);
          break;
        default:
          break;
      }
    });

    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
      socket.off("timerUpdate");
    };
  }, [socket, startTimer, pauseTimer, resumeTimer]);

  useEffect(() => {
    // Function to synchronize the timer with server
    const syncTimer = (endTime, remainingTime) => {
      if (game?.status === "In-Progress") {
        if (remainingTime && remainingTime > 0) {
          startTimer(Date.now() + remainingTime);
        } else if (endTime) {
          startTimer(endTime);
        }

        return;
      }

      if (timerRef.current) clearInterval(timerRef.current);

      if (remainingTime && remainingTime > 0) {
        setTimeLeft(remainingTime);
      }

      if (endTime) {
        setTimeLeft(endTime - Date.now());
      }
    };

    socket.on("syncTimer", (data) => {
      const endTime = parseInt(data.endTime, 10);
      const remainingTime = parseInt(data.remainingTime, 10);
      syncTimer(endTime, remainingTime);
    });

    return () => {
      socket.off("syncTimer");
    };
  }, [socket, game?.status]);

  useEffect(() => {
    socket.on("timerExpired", () => {
      setTimeLeft(0);
    });

    return () => {
      socket.off("timerExpired");
    };
  }, [socket]);

  // Format timeLeft into a readable format
  const formatTime = (milliseconds) => {
    const totalSeconds = Math.round(milliseconds / 1000); // Round to nearest second
    const seconds = totalSeconds % 60;
    const minutes = Math.floor(totalSeconds / 60);

    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };

  if (!game) return null;

  if (["Created", "Ended"].includes(game.status)) return null;

  return (
    <div className="text-center">
      Time left:{" "}
      {formatTime(
        game.status === "Initialized" ? game.turnDuration * 1000 : timeLeft
      )}
    </div>
  );
};

export default Timer;
