import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useStore from "../store";
import LoadingBar from "../components/LoadingBar";
import LoginModal from "../components/LoginModal"; // Import the modal
import { Helmet } from "react-helmet";
import structuredDataVideoGame from "../constants/structuredDataVideoGame";

const Login = () => {
  const navigate = useNavigate();
  const setPlayer = useStore((state) => state.setPlayer);
  const setUserId = useStore((state) => state.setUserId);
  const [showModal, setShowModal] = useState(false);
  // const accessCode = useStore((state) => state.accessCode);
  const accessCode = useStore((state) => state.accessCode);

  useEffect(() => {
    const playerName = localStorage.getItem("Cryptik_playerName");
    const playerToken = localStorage.getItem("Cryptik_playerToken");

    // if (!playerName || !playerToken || !accessCode) {
    if (!playerName || !playerToken) {
      setShowModal(true); // Show modal if user needs to login
    } else {
      setPlayer(playerName, playerToken);
      handleLoginNavigate(); // Navigate to play if already logged in
    }
  }, [navigate, setPlayer, setUserId]);

  const onLoginSuccess = (userData) => {
    localStorage.setItem("Cryptik_userId", userData._id);
    setUserId(userData._id);
    setShowModal(false);
    handleLoginNavigate();
  };

  const handleLoginNavigate = () => {
    const redirectPath =
      localStorage.getItem("Cryptik_redirectPath") || "/play";
    localStorage.removeItem("Cryptik_redirectPath");

    // navigate with a state that indicates the user just logged in
    navigate(redirectPath, { state: { justLoggedIn: true } });
  };

  return (
    <>
      <Helmet>
        <title>Login to Cryptik - A Strategy Game Inspired by Codenames</title>
        <link
          rel="canonical"
          href={`https://www.cryptik.me${window.location.pathname}`}
        />
        <script type="application/ld+json">
          {JSON.stringify(structuredDataVideoGame)}
        </script>
      </Helmet>
      <div className="login-page">
        {showModal && <LoginModal onLoginSuccess={onLoginSuccess} />}
        <LoadingBar />
      </div>
    </>
  );
};

export default Login;
