import React, { useEffect } from "react";
import { FaGear, FaPeopleGroup } from "react-icons/fa6";
import useStore from "../store";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { io } from "socket.io-client";
import Grid from "../components/Grid";
import Settings from "../components/Settings";
import Teams from "../components/Teams/Teams";
import useHandleSocketDisconnect from "../hooks/useHandleSocketDisconnect";

import "./Game.css";
import BreathingBar from "../components/BreathinBar";
import TimeControlButtons from "../components/TimeControlButtons";
import Spectators from "../components/Spectators";

import "./Login.css";
import LoadingBar from "../components/LoadingBar";
import { Helmet } from "react-helmet";
import withTracking from "../utils/withTrackingClick";
import structuredDataVideoGame from "../constants/structuredDataVideoGame";
import Timer from "../utils/Timer";

const Game = () => {
  const navigate = useNavigate();
  const {
    userId,
    game,
    setGame,
    setSocket,
    socket,
    showSettings,
    setShowSettings,
    showSpectators,
    setShowSpectators,
  } = useStore();
  const gameCode = useParams().gameCode;

  const isDisconnected = useHandleSocketDisconnect(socket);

  useEffect(() => {
    const newSocket = io(`${process.env.REACT_APP_SOCKET_URL}`);
    setSocket(newSocket);

    const handleGameStateUpdate = (updatedGameState) => {
      setGame(updatedGameState);
    };

    newSocket.on("connect", () => {
      newSocket.emit("joinGame", { gameCode, userId }, (response) => {
        if (response.error) {
          console.error("Error joining game:", response.error);
        }
      });
      newSocket.on("gameStateUpdate", handleGameStateUpdate);

      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/games/join`, {
          userId,
          gameCode,
        })
        .then((response) => {
          const game = response.data;
          setGame(game);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            alert("Game not found");
            navigate("/home");
          } else {
            console.error("Error joining game:", error);
          }
        });

      newSocket.on("turnChanged", () => {
        const audio = new Audio("/sounds/ding.wav");
        audio
          .play()
          .catch((error) => console.error("Error playing the sound:", error));
      });

      newSocket.on("flashCard", (cardIndex) => {
        const card = document.querySelector(`.card-${cardIndex}`);
        card.classList.add("flash");
        setTimeout(() => {
          card.classList.remove("flash");
        }, 200);
      });
    });

    newSocket.on("disconnect", () => {
      newSocket.off("gameStateUpdate", handleGameStateUpdate);
      newSocket.off("turnChanged");
      newSocket.off("flashCard");
    });

    return () => {
      newSocket.off("gameStateUpdate", handleGameStateUpdate);
      newSocket.off("turnChanged");
      newSocket.off("flashCard");
      newSocket.disconnect();
    };
  }, [userId, gameCode, navigate, setGame, setSocket]);

  const players = game?.players;
  const teams = game?.teams;

  const spectators = players?.filter((player) => {
    return !teams?.some((team) => {
      return (
        team.spymaster?._id === player._id ||
        team.operatives.some((operative) => operative._id === player._id)
      );
    });
  });

  const handleClickShowSpectators = () => {
    setShowSpectators(true);
  };

  const handleClickShowSettings = () => {
    setShowSettings(true);
  };

  if (!game)
    return (
      <>
        <LoadingBar />
        <h3 className="flex justify-center">LOADING...</h3>
      </>
    );

  if (isDisconnected) {
    return (
      <>
        <LoadingBar />
        <p className="flex justify-center">Disconnected</p>
      </>
    );
  }

  return (
    <div className="game-view">
      <Timer />
      <Helmet>
        <title>Cryptik - {game?.code}</title>
        <script type="application/ld+json">
          {JSON.stringify(structuredDataVideoGame)}
        </script>
      </Helmet>
      <TimeControlButtons />
      {!showSpectators && game.status !== "Created" && (
        <button
          className="icon-btn spectators-btn"
          onClick={withTracking(handleClickShowSpectators, "ShowSpectators")}
        >
          <FaPeopleGroup />
          {spectators.length > 0 && (
            <span className="spectators-btn__count">{spectators.length}</span>
          )}
        </button>
      )}

      {game.status !== "Created" && (
        <button
          className={`icon-btn settings-btn`}
          onClick={withTracking(handleClickShowSettings, "ShowSettings")}
        >
          <FaGear />
        </button>
      )}
      {/* <div className="game-hud">
        <p>Current turn: {game.currentTeamIndex}</p>
        <p>Current role: {game.currentRole}</p>
        <p>Current status: {game.status}</p>
      </div> */}

      {(showSettings || game.status === "Created") && <Settings />}
      <Teams />
      {game?.cards?.length > 0 && <BreathingBar />}
      <Grid />
      <Spectators />
    </div>
  );
};

export default Game;
