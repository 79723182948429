import React, { useState } from "react";
import { kickPlayer } from "../../services/kickPlayer";
import { useParams } from "react-router-dom";
import useStore from "../../store";
import { isPlayerOnline } from "../../utils/isPlayerOnline";
import { makeHost } from "../../services/makeHost";
import { FaCrown, FaTimesCircle, FaKey } from "react-icons/fa";
import withTracking from "../../utils/withTrackingClick";

const Player = ({ player, isPulsing }) => {
  const gameCode = useParams().gameCode;
  const userId = useStore((state) => state.userId);
  const game = useStore((state) => state.game);

  const [isHovered, setIsHovered] = useState(false);

  const isHost = (userId) => {
    if (game) {
      return game.host === userId;
    }
  };

  const handleClickMakeHost = () => {
    const confirm = window.confirm("Make this player host. Are you sure?");
    if (confirm) {
      makeHost(gameCode, userId, player._id);
    }
  };

  const handleHoverPlayer = () => {
    setIsHovered(true);
  };

  const handleUnhoverPlayer = () => {
    setIsHovered(false);
  };

  const handleClickKickPlayer = () => {
    kickPlayer(gameCode, userId, player._id);
  };

  const getPlayerColorInGame = () => {
    if (game) {
      const playerWithColor = game.playersWithColors?.find(
        (playerWithColor) => playerWithColor.player._id === player._id
      );
      return playerWithColor.color;
    }
  };

  return (
    <div
      className="flex align-center"
      onMouseEnter={() => handleHoverPlayer()}
      onMouseLeave={() => handleUnhoverPlayer()}
    >
      {isHost(player._id) && (
        <span className="flex align-center">
          {<FaCrown className="mr--2 mt--1" />}
        </span>
      )}

      <span
        className="player__color-circle"
        style={{ backgroundColor: getPlayerColorInGame() }}
      />

      <span
        className={`mr--4 ${
          isPlayerOnline(player, gameCode) ? "" : "player-offline"
        } ${isPulsing ? "player-pulsing" : ""}`}
      >
        {player.name}
      </span>

      {isHost(userId) && !isHost(player._id) && isHovered && (
        <>
          <span className="mt--2 mr--2">
            <button
              className="icon-btn"
              onClick={withTracking(handleClickKickPlayer, "KickPlayer")}
            >
              <FaTimesCircle />
            </button>
          </span>
          <span className="mt--2">
            <button
              className="icon-btn"
              onClick={withTracking(handleClickMakeHost, "MakeHost")}
            >
              <FaKey />
            </button>
          </span>
        </>
      )}
    </div>
  );
};

export default Player;
