import React from "react";
import useStore from "../../store";

const Hints = ({ hints, teamIndex }) => {
  const game = useStore((state) => state.game);

  const getHintsByTeamIndex = (teamIndex) => {
    return hints.filter((hint) => hint.teamIndex === teamIndex);
  };

  const isMostRecentHint = (hint) => {
    return hint === hints[hints.length - 1];
  };

  const isTeamTheCurrentTeam = () => {
    return teamIndex === game.currentTeamIndex;
  };

  return (
    <ul className="hints">
      {getHintsByTeamIndex(teamIndex).map((hint, index) => (
        <li
          key={index}
          className={`${
            isMostRecentHint(hint) &&
            isTeamTheCurrentTeam() &&
            game.status !== "Ended"
              ? "new-hint"
              : ""
          }`}
        >
          {hint.word}
        </li>
      ))}
    </ul>
  );
};

export default Hints;
