import { create } from "zustand";

const useStore = create((set) => ({
  playerName: localStorage.getItem("Cryptik_playerName") || "",
  playerToken: localStorage.getItem("Cryptik_playerToken") || "",
  userId: localStorage.getItem("Cryptik_userId") || "",
  // accessCode: localStorage.getItem("Cryptik_accessCode") || "",

  game: null,
  socket: null,

  showSettings: false,
  showSpectators: false,

  setPlayer: (name, token) => set({ playerName: name, playerToken: token }),
  setUserId: (id) => set({ userId: id }),
  // setAccessCode: (code) => set({ accessCode: code }),

  setGame: (game) => set({ game }),
  setSocket: (socket) => set({ socket }),

  setShowSettings: (show) => set({ showSettings: show }),
  setShowSpectators: (show) => set({ showSpectators: show }),
}));

export default useStore;
