import React from "react";
import useStore from "../store";
import "./Spectators.css";
import { FaChevronLeft } from "react-icons/fa";
import { FaPeopleGroup } from "react-icons/fa6";
import Player from "./Teams/Player";
import withTracking from "../utils/withTrackingClick";

const Spectators = () => {
  const game = useStore((state) => state.game);
  const showSpectators = useStore((state) => state.showSpectators);
  const setShowSpectators = useStore((state) => state.setShowSpectators);

  const players = game?.players;
  const teams = game?.teams;

  const spectators = players.filter((player) => {
    return !teams.some((team) => {
      return (
        team.spymaster?._id === player._id ||
        team.operatives.some((operative) => operative._id === player._id)
      );
    });
  });

  React.useEffect(() => {
    const closeDrawer = (e) => {
      if (
        showSpectators &&
        !e.target.closest(".spectators-drawer") &&
        !e.target.closest(".spectators-btn")
      ) {
        setShowSpectators(false);
      }
    };
    document.addEventListener("click", closeDrawer);
    return () => document.removeEventListener("click", closeDrawer);
  }, [showSpectators, setShowSpectators]);

  const handleClickHideSpectators = () => {
    setShowSpectators(false);
  };

  return (
    <div
      className={`spectators-drawer ${
        showSpectators ? "" : "spectators-drawer--closed"
      }`}
    >
      {spectators.length > 0 ? (
        <>
          <strong className="spectators-title">
            <FaPeopleGroup className="mr--4 mt--2" />
            Spectators
          </strong>
          <ul>
            {spectators?.map((player) => (
              <li key={player._id}>
                <Player player={player} />
              </li>
            ))}
          </ul>
        </>
      ) : (
        <strong>No spectators</strong>
      )}

      <button
        className="icon-btn spectators-drawer__close-btn"
        onClick={withTracking(handleClickHideSpectators, "CloseSpectators")}
      >
        <FaChevronLeft />
      </button>
    </div>
  );
};

export default Spectators;
