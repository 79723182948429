import React from "react";
import "./teams.css";
import Team from "./Team";
import useStore from "../../store";

const Teams = () => {
  const teams = useStore((state) => state.game.teams);

  return (
    <div className="teams">
      {teams.map((team, index) => {
        const color = team.color.toLowerCase();

        return (
          <div key={index} className={`team team--${color}`}>
            <Team team={team} teamIndex={index} />
          </div>
        );
      })}
    </div>
  );
};

export default Teams;
