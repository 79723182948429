import React, { useState } from "react";
import Spymaster from "./Spymaster";
import Operatives from "./Operatives";
import { useGetRemainingCardCount } from "../../utils/useGetRemainingCardCountPerTeam";
import useStore from "../../store";
import { useGetTeamByIndex } from "../../utils/useGetTeamByIndex";
import axios from "axios";
import Hints from "./Hints";
import { BiSad } from "react-icons/bi";
import withTracking from "../../utils/withTrackingClick";

const Team = ({ team, teamIndex }) => {
  const userId = useStore((state) => state.userId);
  const game = useStore((state) => state.game);

  const [hint, setHint] = useState("");

  const isActiveSpymaster =
    useGetTeamByIndex(game.currentTeamIndex)?.spymaster?._id === userId;
  const showHintInput =
    isActiveSpymaster &&
    game.currentTeamIndex === teamIndex &&
    game.currentRole === "Spymaster";

  const handleSubmitHint = (e) => {
    e.preventDefault();
    if (!hint) return;

    if (game.status !== "In-Progress") return;

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/games/submitHint`, {
        gameCode: game.code,
        userId,
        hint,
      })
      .then(() => {
        setHint("");
      });
  };

  const isPlayerOnCurrentTeam = team.operatives.some(
    (operative) => operative._id === userId
  );

  const showEndTurnButton =
    game.currentTeamIndex === teamIndex &&
    game.currentRole === "Operative" &&
    game.status === "In-Progress" &&
    isPlayerOnCurrentTeam;
  const handleClickEndTurn = () => {
    if (!showEndTurnButton) return;

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/games/voteEndTurn`, {
        gameCode: game.code,
        userId,
      })
      .then(() => {});
  };

  return (
    <div className="team__container">
      {game.losingTeamsIndexes.includes(teamIndex) &&
        game.teams.length === 3 && (
          <span className="team__lost">
            <BiSad />
          </span>
        )}
      <span className="team__remaining-count">
        {useGetRemainingCardCount(teamIndex)}
      </span>
      <Spymaster spymaster={team.spymaster} teamId={team._id} />
      <Operatives operatives={team.operatives} teamId={team._id} />
      <Hints hints={game.hints} teamIndex={teamIndex} />

      {showHintInput && (
        <form className="hint__form" onSubmit={handleSubmitHint}>
          <input
            type="text"
            placeholder="Enter hint"
            value={hint}
            onChange={(e) => setHint(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSubmitHint(e);
              }
            }}
          />
          <button onClick={withTracking(null, "Submit-Hint")} type="submit">
            +
          </button>
        </form>
      )}

      {showEndTurnButton && (
        <button
          className="end-turn-btn"
          onClick={withTracking(handleClickEndTurn, "EndTurn")}
        >
          End Turn
          {game?.endTurnVotes.length > 0 && (
            <div className="end-turn-btn__votes">
              {game?.endTurnVotes.length}
            </div>
          )}
        </button>
      )}
    </div>
  );
};

export default Team;
