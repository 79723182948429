import React, { useEffect, useState } from "react";
import "./LoadingBar.css";

const LoadingBar = ({ noAnimation, isGreen }) => {
  // Defining state for animation step
  const [step, setStep] = useState(0);

  // Updating the step for animation
  useEffect(() => {
    const interval = setInterval(() => {
      setStep((prevStep) => (prevStep + 1) % 4);
    }, 250); // Adjust timing for speed

    return () => clearInterval(interval);
  }, []);

  if (noAnimation) {
    return (
      <div className="loading-bar">
        <div className="light red"></div>
        <div className="light blue"></div>
      </div>
    );
  }

  if (isGreen) {
    return (
      <div className="loading-bar">
        <div className="light green breathe"></div>
      </div>
    );
  }

  return (
    <div className="loading-bar">
      <div
        className={`light red ${step === 0 ? "sweep" : ""} ${
          step === 1 ? "flicker" : ""
        } ${step === 2 ? "pulse" : ""}`}
      ></div>
      <div
        className={`light blue ${step === 0 ? "sweep" : ""} ${
          step === 1 ? "flicker" : ""
        } ${step === 2 ? "pulse" : ""}`}
      ></div>
    </div>
  );
};

export default LoadingBar;
