import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./views/Home";
import Game from "./views/Game";
import Login from "./views/Login";
import ProtectedRoute from "./ProtectedRoute";
import Landing from "./views/Landing";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/play"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/game/:gameCode"
        element={
          <ProtectedRoute>
            <Game />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default App;
